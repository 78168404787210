<template>
  <v-app>
    <fullscreen v-model="fullscreen">
      <div>
        <v-speed-dial
          v-model="fab"
          fixed
          top
          left
          direction="right"
          transition="slide-x-transition"
          style="left: 0;"
        >
          <template v-slot:activator>
            <v-btn
              v-model="fab"
              dark
              fab
              small
              color="grey"
              class="opacity-4 rounded-l-0"
            >
              <v-icon v-if="fab">
                mdi-close
              </v-icon>
              <v-icon v-else>
                mdi-menu-open
              </v-icon>
            </v-btn>
          </template>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                fab
                small
                color="primary"
                v-bind="attrs"
                to="/"
                v-on="on"
              >
                <v-icon>mdi-home</v-icon>
              </v-btn>
            </template>
            <span>Home</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="false"
                dark
                fab
                small
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="showwrap"
              >
                <v-icon>mdi-wallet</v-icon>
              </v-btn>
            </template>
            <span>Wallet</span>
          </v-tooltip>
        </v-speed-dial>
      </div>

      <iframe
        id="gameplay"
        class="iframe-preview"
        width="100%"
        :src="gameurl"
      />

      <Wrap
        v-if="showdialogwrap"
        @closewrap="closewrap"
      />

      <v-overlay :value="overlay">
        <!--
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        />
        -->
        <v-img
          src="../../assets/img/hand.gif"
          @click="quanping"
        />
      </v-overlay>

      <v-dialog
        v-model="newtip"
        max-width="300"
        content-class="rounded-xl"
      >
        <v-card
          flat
          class="text-center"
        >
          <v-card-text class="pt-4">
            <v-icon
              color="primary"
              size="56"
            >
              mdi-emoticon
            </v-icon>
            <h3 class="my-2">
              {{ $t('newtip_biaoti') }}
            </h3>
            {{ $t('newtip_neirong') }}!

            <v-btn
              depressed
              block
              color="primary"
              class="mt-3"
              @click="download"
            >
              <v-icon left>
                mdi-download
              </v-icon>
              {{ $t('xiazai') }} APP
            </v-btn>
          </v-card-text>
        </v-card>
        <v-btn
          icon
          dark
          class="mt-5 opacity-5"
          style="position: absolute; left: 50%; transform: translate(-50%);"
          @click="newtip = false"
        >
          <v-icon large>
            mdi-close-circle
          </v-icon>
        </v-btn>
      </v-dialog>

      <template>
        <v-dialog
          v-model="iosapp"
          width="400"
          content-class="dialog-end"
        >
          <v-sheet
            dark
            color="nav_bg"
            class="pa-5 box-down-arrow rounded-lg"
          >
            <div class="position-relative">
              <v-btn
                fab
                icon
                small
                absolute
                top
                right
                color="grey darken-2"
                class="mr-n8"
                @click="iosapp = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>

            <h5 class="pb-3 text--secondary">
              {{ $t('azdsjzm') }}
            </h5>
            <div class="text-body-2 text--disabled">
              {{ $t('azdsjzmnr1') }}<v-icon
                color="blue"
                class="mx-1 mt-n2"
              >
                mdi-export-variant
              </v-icon>{{ $t('azdsjzmnr2') }}
            </div>
            <div class="mt-3 d-flex justify-space-around">
              <v-btn
                small
                outlined
                color="primary"
                @click="iosapp_help = true"
              >
                {{ $t('rhcz') }}?
              </v-btn>
              <v-btn
                small
                outlined
                color="primary"
                @click="iosapp_help = false"
              >
                {{ $t('wyaz') }}
              </v-btn>
            </div>
          </v-sheet>
        </v-dialog>

        <v-dialog
          v-model="iosapp_help"
          width="400"
        >
          <v-card
            color="box_bg"
          >
            <v-card-title
              dense
              class="text-body-1"
            >
              {{ $t('rhcz') }}?
              <v-spacer />
              <v-btn
                fab
                icon
                small
                color="grey darken-2"
                @click="iosapp_help = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-carousel
                hide-delimiters
                :continuous="false"
                height="100%"
              >
                <v-carousel-item>
                  <img
                    width="100%"
                    src="../../assets/img/pic/help-ios01.jpg"
                  >
                </v-carousel-item>
                <v-carousel-item>
                  <img
                    width="100%"
                    src="../../assets/img/pic/help-ios02.jpg"
                  >
                </v-carousel-item>
                <v-carousel-item>
                  <img
                    width="100%"
                    src="../../assets/img/pic/help-ios03.jpg"
                  >
                </v-carousel-item>
              </v-carousel>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </fullscreen>
  </v-app>
</template>

<script>
//import { Crisp } from "crisp-sdk-web"
import Wrap from '@/components/Wrap.vue'
export default {
  name: 'GameId',
  components:{
    Wrap,
  },
  data: () => ({
    downloadurl: '',
    fab: false,
    showdialogwrap: false,
    overlay: false,
    fullscreen: false,
    newtip: false,
    player_id: 0,
    meminfo:{
      id:0,
      avatar: 1,
      assets: {
        
      }
    },
    gameurl: '',
    iosapp: false,
    iosapp_help: false,
    contentHtml: ''
  }),
  watch: {
    /*
    overlay (val) {
      val && setTimeout(() => {
        this.overlay = false
      }, 5000)
    },
    */
  },
  created() {

    console.log(this.$route.query)
    console.log(this.$route.params)

    this.$i18n.locale = localStorage.getItem('Lang')?localStorage.getItem('Lang'):'pt'
    this.$vuetify.lang.current = localStorage.getItem('Lang')?localStorage.getItem('Lang'):'pt'

    //Crisp.chat.hide()
  },
  mounted() {
    if(this.getStorage('player_id')){
      this.getmeminfo()
      this.player_id = this.getStorage('player_id')
      this.entergame()
    }
    console.log('===',document.documentElement.clientWidth, document.documentElement.clientHeight)
    this.$nextTick(() => {
      let width = document.documentElement.clientWidth
      let height = document.documentElement.clientHeight
      if(width > height) {
        this.overlay = false
        console.log('横屏')
        if (this._isMobile() && this.fullscreen == false) {
          this.overlay = true
        }
      }else{
        this.overlay = false
      }
    })
    // 监听 resize 方法
    window.addEventListener("resize", this.renderResize, false)

    this.$server.conf().then((response) => {
      this.downloadurl = response.data.download
    })
  },
  beforeDestroy () {
    // 移除监听
    window.removeEventListener("resize", this.renderResize, false)
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    quanping () {
      this.fullscreen = true
      this.overlay = false
    },
    renderResize() {
      // 判断横竖屏
      let width = document.documentElement.clientWidth
      let height = document.documentElement.clientHeight
      if(width > height) {
        this.overlay = false
        console.log('横屏')
        if (this._isMobile() && this.fullscreen == false) {
          this.overlay = true
        }
      }else{
        this.overlay = false
      }
      // 做页面适配
      // 注意：renderResize 方法执行时虚拟 dom 尚未渲染挂载，如果要操作 vue 实例，最好在this.$nextTick()里进行。
    },
    getmeminfo() {
      let paramObj = {
        player_id: this.getStorage('player_id'),
      }
      this.$server.getmeminfo(paramObj).then((response) => {
        if(response.code==200){
          this.meminfo = response.data
          if(this.meminfo.deposited==1 && localStorage.getItem('newtip')!='deposited'){
            this.newtip = true
            localStorage.setItem('newtip', 'deposited')
          }
        }
      })
    },
    showwrap() {
      this.showdialogwrap = true
    },
    closewrap() {
      this.showdialogwrap = false
    },
    entergame() {
      let paramObj = {
        player_id: this.player_id,
        game_id: this.$route.params.id,
        free: this.$route.params.free
      }
      this.$server.entergame(paramObj).then((response) => {
        if(response.code==200){
          if(response.data.loginhtml){
            this.contentHtml = response.data.loginhtml
            this.handleHtml()
          }else{
            this.gameurl = response.data.gameurl
          }
        }else{
          this.$snackbar.error(response.msg)
        }
      })
    },
    handleHtml() {
      var content = this.contentHtml
      var iframe = document.getElementById("gameplay");
        if (iframe !== null && content.length > 0) {
          var ifrdoc = iframe.contentWindow.document;
          //开启文档的编辑模式
          ifrdoc.designMode = "on"; 
          //打开数据流
          ifrdoc.open(); 
          //写入内容
          ifrdoc.write(content);
          //关闭数据流
          ifrdoc.close();
          //关闭文档编辑模式
          ifrdoc.designMode ="off"; 
        }else {
          iframe.style.height = '0'
        }
    },
    download() {
      if(!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)){
        this.iosapp = true
      }else{
        window.open(this.downloadurl)
      }
    },
    back() {
      this.$router.back()
    }
  },
};
</script>

<style>
  .iframe-preview{position:absolute;height:calc(100% - 0px);width:100%;border:none;margin-top:0px}
  .iframe-preview-mobile{width:375px;left:50%;transform:translateX(-50%)}
</style>