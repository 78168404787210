<template>
  <v-dialog
    v-model="showdialogdeposit"
    persistent
    scrollable
    max-width="550"
  >
    <v-card color="app_bg">
      <v-toolbar
        flat
        color="nav_bg"
      >
        <v-toolbar-title>{{ $t('cunkuan') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          @click="closewrap"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <template v-if="showdeposit">
        <Deposit @qrcodeshow="qrcodeshow(arguments)" />
      </template>
      <v-card-text
        v-if="showQrcode"
        class="px-3"
      >
        <v-card-subtitle class="pb-0">
          <v-img
            width="150"
            src="../assets/img/logos/pay-pix.png"
            class="mx-auto"
          />
        </v-card-subtitle>
        <div class="text-center pb-3">
          <span class="mr-2">{{ $t('ckje') }}:</span>
          <strong>R$</strong>
          <strong
            class="title green--text"
          >
            {{ select_amount }}
          </strong>
          BRL

          <v-btn
            icon
            small
            right
            color="grey darken-1"
            @click="doCopy(select_amount.toString())"
          >
            <v-icon small>
              mdi-content-copy
            </v-icon>
          </v-btn>
        </div>

        <v-sheet
          rounded
          class="box_bg px-3 pb-5"
        >
          <v-subheader>{{ $t('ckmx') }}</v-subheader>

          <v-alert
            text
            dense
            color="primary"
            icon="mdi-clock-fast"
            border="left"
            class="caption"
          >
            {{ $t('qz10fznfk') }}
          </v-alert>

          <div class="text-center py-3">
            <v-img
              width="150"
              height="150"
              :src="erweima"
              class="mx-auto"
            />
            <small>{{ $t('smewm') }}</small>
          </div>

          <v-divider class="opacity-3" />

          <v-text-field
            outlined
            readonly
            hide-details
            label="PIX CODE"
            class="mb-3"
            :value="qr_code_omit"
            @click="doCopy(qr_code)"
          />
            
          <v-btn
            block
            depressed
            color="primary"
            class="text-none"
            @click="doCopy(qr_code)"
          >
            {{ $t('fzpixcode') }}
          </v-btn>
        </v-sheet>

        <div class="my-3 text-disabled px-4">
          <h5 class="subtitle-2">
            {{ $t('zmfk') }}?
          </h5>
          <p>
            {{ $t('zmfk2') }}
          </p>
        </div>
        <div class="text-center pb-5">
          <v-btn
            depressed
            rounded
            dark
            color="grey darken-4"
            :loading="loading"
            @dblclick="dblclick"
            @click="depositcompleted"
          >
            <v-icon left>
              mdi-cash-check
            </v-icon>
            {{ $t('wyzfcg') }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Deposit from '@/components/Deposit.vue'
export default {
  name: 'Wrap',
  components:{
    Deposit
  },
  props: {

  },
  data: () => ({
    showdialogdeposit: true,
    showdeposit: true,
    showQrcode: false,
    select_amount: 0,
    qr_code:'',
    qr_code_omit: '',
    erweima:'',
    loading: false,
  }),
  computed: {

  },
  watch:{

  },
  created() {

  },
  mounted() {

  },
  methods: {
    closewrap() {
      this.showdialogdeposit = false
      this.$emit('closewrap')
    },
    qrcodeshow(param) {
      //console.log(param)
      this.select_amount = param[0]
      this.qr_code = param[1]
      this.qr_code_omit = param[2]
      this.erweima = param[3]
      this.showdeposit = false
      this.showQrcode = true
    },
    depositcompleted() {
      this.loading = true
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.depositafter(paramObj).then((response) => {
        if(response.code==200){
          this.closewrap()
        }
        setTimeout(() => {
          this.loading = false
        }, 2000)
      })
    },
    dblclick() {
      console.log('dblclick')
    },
    doCopy: function (text) {
      this.$copyText(text, this.$refs.wallet).then( (e)=>{
        console.log(e)
        this.$snackbar.info(this.$t('fzcg'))
      }, function (e) {
        console.log(e)
      })
    },
  },
};
</script>